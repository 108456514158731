<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Purchase Event Logs</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <KioskLogs
          :columns="columns"
          :current-page="currentPage"
          :total-rows="totalRows || 0"
          :logs="transactionLogs || []"
          :loading="isLoading"
          :fetch-error="errorFetching"
          no-log-message="No Purchase Logs Found"
          @pageChange="onPageChange"
          @detailsClick="onDetailsClick"
      >
      </KioskLogs>
    </template>
  </PageTemplate>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import 'vue-good-table/dist/vue-good-table.css'
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "@/components/Helpers/Navs/BasicNav";
import KioskLogs from "@/components/KioskLogs";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";

export default {
  name: "TransactionLogs",
  mixins: [LocalLogFormatters],
  components: {KioskLogs, BasicNav, PageTemplate},
  data(){
    return {
      errorFetching: false,
      isLoading: true,
      columns: [
        {
          label: 'Event',
          field: 'event',
          sortable: false,
          formatFn: this.capitalizeWords,
          type: String,
        },
        {
          label: 'Status',
          field: 'status',
          type: 'String',
          formatFn: this.formatSuccessfulTransaction,
          sortable: false,
        },
        {
          label: 'Date/Time',
          field: 'createDateTime',
          formatFn: this.formatLogDate,
          sortable: false,
          type: 'string',
        },
        {
          label: 'Confirmation #',
          field: 'confirmation',
          sortable: false,
          type: 'string',
        },
        {
          label: 'Details',
          field: 'details',
          sortable: false,
        },
      ],
    };
  },
  computed:{
    ...mapGetters('TransactionLogs', ['transactionLogs', "currentPage", "totalRows"]),
  },
  methods: {
    ...mapActions('TransactionLogs', ['fetchTransactionLogs']),
    ...mapMutations('TransactionLogs', ['updateParams']),
    onPageChange(params) {
      this.updateParams({page: params.currentPage -1});
      this.loadItems();
    },
    async loadItems() {
      this.isLoading = true;
      try{
        await this.fetchTransactionLogs();
      }catch {
        this.errorFetching = true;
      }
      this.isLoading = false;
    },
    onDetailsClick(props) {
      if(props.paymentType.toUpperCase() === "CASH"){
        this.$router.push({
          name: "CashTransactionDetail",
          params: { id: props.purchaseId }
        });
      }else if(props.paymentType.toUpperCase() === "CREDIT_CARD"){
        this.$router.push({
          name: "VerifoneDetails",
          params: { id: props.purchaseId }
        });
      }
    }
  },
  mounted() {
    this.loadItems();
  }

}
</script>
<style>
.chevron.right::after {
  border-left-color: rgba(245, 120, 18, 1) !important;
}
.chevron.left::after {
  border-right-color: rgba(245, 120, 18, 1) !important;
}
</style>
