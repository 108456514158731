var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-cols-1 mb-8"},[(_vm.fetchError)?_c('div',{staticClass:"m-auto"},[(_vm.fetchError)?_c('ErrorCard',{attrs:{"message":"Purchase Logs Not Available"}}):_vm._e()],1):_c('div',[_c('div',{staticClass:" w-full"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRows,"isLoading":_vm.loading,"max-height":"750px","theme":"polar-bear","sort-options":{
          enabled: false,
          },"pagination-options":{
            enabled: true,
            perPage: 10,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
            setCurrentPage: _vm.currentPage + 1,
          },"rows":_vm.logs,"columns":_vm.columns,"styleClass":"vgt-table bordered","row-style-class":"leading-loose"},on:{"on-page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field !== 'details')?_c('span',{class:[props.column.field === 'createDateTime' && 'whitespace-nowrap', 'text-md', 'font-medium','ml-4']},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):_c('span',{staticClass:"flex justify-center"},[_c('button',{on:{"click":function($event){return _vm.detailsClicked(props.row)}}},[_c('font-awesome-icon',{staticClass:"text-orange",attrs:{"icon":['far', 'plus'],"size":"2xl"}})],1)]),_vm._t("table-row",null,{"props":Object.assign({}, props)})]}},{key:"table-column",fn:function(props){return [_c('span',{class:[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange block p-0', 'text-lg']},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}}],null,true)},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('LoadingCard')],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.noLogMessage)+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }