<template>
  <div class="grid grid-cols-1 mb-8">
    <div class="m-auto"  v-if="fetchError">
      <ErrorCard v-if="fetchError" message="Purchase Logs Not Available"/>
    </div>
    <div v-else>
      <div class=" w-full">
        <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            :totalRows="totalRows"
            :isLoading="loading"
            max-height="750px"
            theme="polar-bear"
            :sort-options="{
            enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 10,
              perPageDropdownEnabled: false,
              dropdownAllowAll: false,
              setCurrentPage: currentPage + 1,
            }"
            :rows="logs"
            :columns="columns"
            styleClass="vgt-table bordered"
            row-style-class="leading-loose"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field !== 'details'" :class="[props.column.field === 'createDateTime' && 'whitespace-nowrap', 'text-md', 'font-medium','ml-4']">
              {{props.formattedRow[props.column.field]}}
            </span>
            <span v-else class="flex justify-center">
              <button @click="detailsClicked(props.row)">
                <font-awesome-icon :icon="['far', 'plus']" class="text-orange" size="2xl" />
              </button>
            </span>
            <slot name="table-row" :props="{ ...props}"></slot>
          </template>
          <template slot="table-column" slot-scope="props">
             <span :class="[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange block p-0', 'text-lg']">
            {{props.column.label}}
             </span>
          </template>
          <div slot="loadingContent">
            <LoadingCard/>
          </div>
          <div slot="emptystate">
            {{ noLogMessage }}
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import ErrorCard from "./Helpers/Pages/ErrorCard";
import LoadingCard from "./Helpers/Loading/LoadingCard";

export default {
  name: "KioskLogs",
  components: {ErrorCard, VueGoodTable, LoadingCard},
  props: {
    columns: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true
    },
    totalRows: {
      type: Number,
      required: true,
    },
    logs: {
      type: Array,
      required: true,
    },
    noLogMessage: {
      type: String,
      default: "No Logs",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fetchError: {
      type: Boolean,
      default: false,
    }
  },
  computed:{
    ...mapGetters('GeneralSettings', ['kioskDetails']),
  },
  methods: {
    onPageChange(params) {
      this.$emit('pageChange', params);
    },
    detailsClicked(row) {
      this.$emit("detailsClick", row);
    }
  }
}
</script>
<style>
.chevron.right::after {
  border-left-color: rgba(245, 120, 18) !important;
}
.chevron.left::after {
  border-right-color: rgba(245, 120, 18) !important;
}
</style>
